import { Ray, RayColliderIntersection } from "@dimforge/rapier3d-compat";
import { Vector3 } from "three";

import { difference } from "~/utils/setOps";
import { AVATAR_HEIGHT ,CAMERA_PLAY_ZOOM_MIN, CAMERA_PLAY_ZOOM_MAX} from "~/config/constants";

import { Entity, System } from "~/ecs/base";
import { Presentation, Transform } from "~/ecs/plugins/core";
import { TranslucentApplied } from "~/ecs/plugins/translucent";

import { Perspective } from "../Perspective";
import { Physics } from "../../physics";

// import { CameraManager } from "../../../../world/CameraManager";
import { worldManager } from "~/world";
// import { viewportScale } from "~/stores";
// import { number } from "svelte-i18n";
var buffer = new Array(15);

var bufferflag = 0;
var zoomflag = 0;

const vDir = new Vector3();
const entitiesBetween: Set<Entity> = new Set();
const emptySet: Set<Entity> = new Set();

export class PerspectiveSystem extends System {
  physics: Physics;
  perspective: Perspective;
  presentation: Presentation;

  entitiesWeMadeTranslucent: Set<Entity> = new Set();
  target: Vector3 = new Vector3();

  init({ physics, perspective, presentation }) {
    this.physics = physics;
    this.perspective = perspective;
    this.presentation = presentation;
    
  }

  update() {
    this.perspective.update();
    this.updateTranslucentObjectsBlockingView();
  }

  updateTranslucentObjectsBlockingView() {
    const blockingEntities = this.getVisuallyBlockingEntities();

    for (let entity of blockingEntities) {
      
      const applied = entity.get(TranslucentApplied);
      if (entity && applied && !this.entitiesWeMadeTranslucent.has(entity)) {
        applied.direction = "END";
        applied.modified();
        this.entitiesWeMadeTranslucent.add(entity);
      }
 
    }



    const noLongerTranslucents: Set<Entity> = difference(
      this.entitiesWeMadeTranslucent,
      blockingEntities
    );
    for (let entity of noLongerTranslucents) {
      const applied = entity.get(TranslucentApplied);
      applied.direction = "START";
      applied.modified();
      this.entitiesWeMadeTranslucent.delete(entity);
    }


    // if (blockingEntities.size>0)
    // {bufferflag =1;}

  // if (bufferflag ==1){

  //   // console.log("before blockingEntities",blockingEntities)
  //   //  if (blockingEntities.size>0)
  //   // {worldManager.camera.setZoomRange(2,5);}
  //   // else{worldManager.camera.setZoomRange(CAMERA_PLAY_ZOOM_MIN,CAMERA_PLAY_ZOOM_MAX);}
  //   // console.log(" blockingEntities",blockingEntities)
  //   if (this.entitiesWeMadeTranslucent.size>0)
  //   {   

 

  //    buffer.push(1);
  //     buffer.shift();}
  //     else{    buffer.push(0);
  //       buffer.shift();}
      
  //       var sumBuffer = buffer.reduce(function(prev, next, index, array) {
  //         return prev + next;
  //       })
  //       if (sumBuffer>10 && zoomflag==0)    { worldManager.camera.setZoomRange(1,3);   zoomflag=1;         
  //       }
  //       if (sumBuffer<=10 && zoomflag==1)   {worldManager.camera.setZoomRange(CAMERA_PLAY_ZOOM_MIN,CAMERA_PLAY_ZOOM_MAX); zoomflag=0}
  //   }
    
  }

  fastFindBetween(source: Vector3, target: Vector3) {
    vDir.copy(target).sub(source).normalize();
    const ray: Ray = new this.physics.rapier.Ray(source, vDir);
    const distance = source.distanceTo(target);
    // console.log("distance",distance)

    entitiesBetween.clear();

    this.physics.world.intersectionsWithRay(
      ray,
      distance,
      false,
      (intersect: RayColliderIntersection) => {
        const entity = this.physics.colliders.get(intersect.collider.handle);
        entitiesBetween.add(entity);
        return true;
      }
    );

    return entitiesBetween;
  }

  fastFindBetweenfix(source: Vector3, target: Vector3) {
    // console.log("source",source)
    // console.log("target",target)


    vDir.copy(source).sub(target).normalize();

    // console.log("vDir",vDir)

    const ray: Ray = new this.physics.rapier.Ray(target, vDir);
    const distance = 13;
        const distance2 = source.distanceTo(target);

    // console.log("distance",distance)
    // console.log("distance2",distance2)

    entitiesBetween.clear();

    this.physics.world.intersectionsWithRay(
      ray,
      distance,
      false,
      (intersect: RayColliderIntersection) => {
        const entity = this.physics.colliders.get(intersect.collider.handle);
        entitiesBetween.add(entity);
        return true;
      }
    );

    return entitiesBetween;
  }



  getVisuallyBlockingEntities(): Set<Entity> {
    const source = this.presentation.camera.parent?.position;
    const transform = this.perspective.avatar?.get(Transform);
    if (source && transform) {
      this.target.copy(transform.position);
      this.target.y += AVATAR_HEIGHT ;

      const entities: Set<Entity> = this.fastFindBetween(source, this.target);
      entities.delete(this.perspective.avatar);
      return entities;
    }
    return emptySet;
  }
}
